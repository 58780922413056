import {Typography} from "../Typography";
import * as styles from './BlogPostContent.module.scss';
import React, {useLayoutEffect, useRef} from 'react';
import classNames from 'classnames';
import {MarkdownContent} from "../MarkdownContent";
import {CTAButtonNew} from "../CTAButtonNew";
import {MarkdownRenderer} from "../MarkdownRenderer";

export function BlogPostContent({className, ctaButton, ...otherProps}) {
    const contentRef = useRef();
    const ctaRef = useRef();
    useLayoutEffect(() => {
        if (contentRef.current && ctaRef.current) {
            const elements = contentRef.current.querySelectorAll('.cta-button');

            for (const element of elements) {
                if (!element.querySelector('a')) {
                    element.appendChild(ctaRef.current.cloneNode(true));
                }
            }
        }
    }, []);

    return <>
        <div ref={contentRef}>
            <MarkdownRenderer
                reduceHeaderVariantBy={2}
                isDocumentTypography
                reduceHeaderLevelBy={1}
                className={classNames(className, styles.root)} {...otherProps} />
        </div>
        {ctaButton && <div ref={ctaRef} className={styles.ctaButtonWrapper}>
            <CTAButtonNew type={ctaButton.type} label={ctaButton.label}/>
        </div>}
    </>;
}
