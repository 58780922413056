import React from 'react';
import classNames from "classnames";
import {SVGSupportImg} from "../SVGSupportImg";
import {LangLink} from "../LangLink";
import {sendAnalyticsEvent} from "../../functions/sendAnalyticsEvent";

const styles = require('./ServiceBanner.module.scss');

export function ServiceBanner({className, slug, title, text, image, ...props}) {
    return <article className={classNames(className, styles.root)} {...props}>
        <div className={styles.image}>
            <SVGSupportImg {...image} />
        </div>
        <div className={styles.content}>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.text}>{text}</p>
            <LangLink className={styles.cta} to={`/service/${slug}`} onMouseDown={() => {
                sendAnalyticsEvent('service-banner.click', 'service-banner', slug);
            }}>Learn more</LangLink>
        </div>
    </article>
}
