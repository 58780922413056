import {graphql, useStaticQuery} from "gatsby";

const query = graphql`
    query siteMetadata {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`;

/**
 *
 * @returns {SiteMetadata}
 */
export function useSiteMetadata() {
    const {site} = useStaticQuery(query)
    return site.siteMetadata;
}

/**
 * @typedef {Object} SiteMetadata
 * @property {string} siteUrl
 */
