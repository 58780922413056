import {useLocation} from "@reach/router";
import {useSiteMetadata} from "./useSiteMetadata";


export function useCurrentUrl() {
    const {pathname} = useLocation();

    const site = useSiteMetadata();

    return site.siteUrl + pathname;
}
