// extracted by mini-css-extract-plugin
export var aside = "blogPost-module--aside--f6e62";
export var blogContent = "blogPost-module--blogContent--935c1";
export var featuredBlogPosts = "blogPost-module--featuredBlogPosts--6b851";
export var mainContent = "blogPost-module--mainContent--60f91";
export var mainContentWrapper = "blogPost-module--mainContentWrapper--21482";
export var newsletterForm = "blogPost-module--newsletterForm--8c775";
export var newsletterFormSmall = "blogPost-module--newsletterFormSmall--82092";
export var share = "blogPost-module--share--318a4";
export var stickyPart = "blogPost-module--stickyPart--08b00";
export var stickyWrapper = "blogPost-module--stickyWrapper--c5983";