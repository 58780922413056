import {useSiteMetadata} from "./useSiteMetadata";
import {useCallback} from "react";

export function useUrlToPath() {
    const site = useSiteMetadata();

    return useCallback((path) => {
        return `${site.siteUrl.replace(/\/$/, '')}/${(path || '').replace(/^\//, '')}`;
    }, [site]);
}
