import React, {useEffect, useRef} from 'react';
import classNames from 'classnames';
import * as styles from './ShareLinkBox.module.scss';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {componentRef} from "../../functions/componentRef";

const clipboardCopy = require('clipboard-copy');

export function ShareLinkBox({url, title, baseUrl, className, ...otherProps}) {
    return <div className={classNames(className, styles.root)} {...otherProps}
                 {...componentRef('share-link-box')}>
        <div className={styles.links}>
            <LinkedInShare url={url} title={title}/>
            <TwitterShare url={url} title={title}/>
            <CopyLinkShare url={url} baseUrl={baseUrl}/>
        </div>
    </div>
}

function LinkedInShare({url, title, className, ...otherProps}) {
    return <button
        onClick={() => {
            const finalURL = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
            global.open(finalURL,
                '',
                '_blank, width=500, height=500, resizable=yes, scrollbars=yes'
            );
        }}
        className={classNames(styles.link, className, styles.linkLinkedIn)} {...otherProps}
        {...componentRef.subcomponent('linkedin')}
    >

    </button>
}

function TwitterShare({url, title, className, ...otherProps}) {
    return <button
        onClick={() => {
            const finalURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title + ' ' + url)}`;
            global.open(finalURL,
                '',
                '_blank, width=500, height=500, resizable=yes, scrollbars=yes'
            );
        }}
        className={classNames(styles.link, className, styles.linkTwitter)} {...otherProps}
        {...componentRef.subcomponent('twitter')}>
    </button>
}

const PRESS_TO_COPY = 'Press to copy link';

function CopyLinkShare({url, baseUrl, className, ...otherProps}) {
    const ref = useRef(null)

    useEffect(() => {
        tippy(ref.current, {
            content: PRESS_TO_COPY,
            hideOnClick: false
        });
    }, []);

    return <a href={baseUrl}
              ref={ref}
              {...componentRef.subcomponent('copy-link')}
              onClick={(e) => {
                  e.preventDefault();
                  clipboardCopy(url)
                      .then((result) => {
                          if (!ref.current._tippy) {
                              return;
                          }
                          ref.current._tippy.setContent('Link copied!');
                          setTimeout(() => {
                              ref.current._tippy.hide();
                              setTimeout(() => {
                                  ref.current._tippy.setContent(PRESS_TO_COPY);
                              }, 500);
                          }, 2000);
                      });
              }}
              className={classNames(styles.link, className, styles.linkLink)} {...otherProps}>
    </a>
}
