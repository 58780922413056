import React from 'react';
import classNames from 'classnames';
import * as styles from './Author.module.scss';
import {GatsbyImage} from "gatsby-plugin-image";

export function Author({name, avatar, className, ...otherProps}) {
    return (
        <div className={classNames(className, styles.root)} {...otherProps}>
            {avatar ? <GatsbyImage image={avatar.gatsbyImageData} className={styles.avatar} alt={name}/> : undefined}
            {name}
        </div>
    );
}
